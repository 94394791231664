import {
  PUT_ASSET_ATTRIBUTE,
  POST_ASSET,
}                    from '../operations'
import { sync, act } from "../../lib/APIRequest"

export default {
  [POST_ASSET]: (context, payload) => sync(context, POST_ASSET, payload),
  [PUT_ASSET_ATTRIBUTE]: (context, payload) => act(context, PUT_ASSET_ATTRIBUTE, payload),
  // [PATCH_VERSION_ATTRIBUTE]: (context, payload) => act(context, PATCH_VERSION_ATTRIBUTE, payload),
}
