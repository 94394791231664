import store                from '@/store'
import { UPDATE_COLLECTION } from '@/store/graph/operations'

/*
  USE ONLY FOR INTERNAL MUTATIONS
 */

export class CollectionPipe {
  /*
      collectionUpdate: [
        {
          [assetId]: patch,
          ...
        },
        ...
      ]
   */
  collectionName

  constructor(collectionName) {
    this.collectionName = collectionName
  }

  updateCollection(updates) {
    store.commit(UPDATE_COLLECTION, {
      collectionName: this.collectionName,
      updates
    })
  }

  deleteItem(id) {
    this.updateCollection([{ [id]: undefined }])
  }
}
