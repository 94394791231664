import * as actions from "../../../operations"

export default {
  [actions.POPUP_MESSAGE]: function (state, popup) {
    state['popup'][popup.id] = popup
  },
  [actions.DISMISS_POPUP]: function (state, id) {
    delete state['popup'][id]
  },
}
