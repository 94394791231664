import personActions from './person'
import serviceAssetActions from './serviceAsset'
import assetActions      from './asset'
import locusActions      from './locus'

export default {
  ...assetActions,
  ...serviceAssetActions,
  ...locusActions,
  ...personActions,
}
