import { parseAction }   from './parseAction'
import { getRequestUrl } from "./getRequestUrl"

let headerVariableNames = []
export function getActionRequestObject(action, data) {
  let request = {}

  headerVariableNames = []

  try {

    request.headers = getHeader()

    parseAction(
      action,
      method => request.method = method,
      template => request.url = getRequestUrl(template, data),
      requiredParams => request.data = checkParameters(requiredParams, data)
    )

  } catch (err) {

    throw `getActionRequestObject.${err}
action: "${action}"`
  }

  return request
}

export function getHeader() {

  const Authorization = localStorage.getItem(`Authorization`) ?? ''

  let headers = {
    'Content-Type': 'application/json;charset=utf-8',
    'X-Requested-With': 'XmlHttpRequest',
  }

  if (Authorization) {

    headers['Authorization'] = Authorization
    headerVariableNames.push('Authorization')

  }
  return headers
}

function checkParameters(requiredNames, data) {
  let parameters = {...data}

  if (requiredNames && requiredNames.length) {
    requiredNames.forEach(name => {
      const isOptional = name[name.length -1] === '?'
      if (isOptional) name = name.substring(0, name.length -1)

      const isUndefined = data[name] === undefined && headerVariableNames.indexOf(name) < 0
      if (!isOptional && isUndefined) throwMissingParameterException(name)
    })
  }
  return parameters
}

function throwMissingParameterException(name) {
  throw `pickRequiredParameters ERROR
parameter "${name}" is required.`
}
