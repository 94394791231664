import APIRequestActions                                 from './APIRequests'
import errorActions                                      from './errors'
import popupMessageActions                               from './popupMessages'
import feedbackActions                                   from './feedback'
import UIStateActions                                      from './UIStates'
import { updateStoreState, updateCollection, updateAsset } from '@/store/lib/storeHelpers/storeHelpers'
import { UPDATE_ASSET, UPDATE_COLLECTION, UPDATE_STATE, SET_SERVICENAME } from '@/store/graph/operations'
import slottedRequestMutations                           from './slottedRequests'

export default {
  ...feedbackActions,
  ...popupMessageActions,
  ...UIStateActions,
  ...slottedRequestMutations,
  ...APIRequestActions,
  ...errorActions,
  [UPDATE_STATE]: updateStoreState,
  [UPDATE_COLLECTION]: updateCollection,
  [UPDATE_ASSET]: updateAsset,
  [SET_SERVICENAME]: function (state, value) {
    state.serviceName = value
  },
}
