import { getStoredToken }   from "@/store/graph/mutations/lib/localStorage"
import jsonWebToken         from "jsonwebtoken"

export default function (token) {
  const storedToken = !token && getStoredToken()
  const availableToken = token || storedToken
  let person
  if (availableToken) {
    person = jsonWebToken.decode(availableToken)
  }
  return person
}
