export function parseAction(action, onMethod, onUrlTemplate, onRequiredParameters) {

  const rxMethod = '(GET|POST|PUT|PATCH|DELETE)'
  const rxParams = '(?:{([^}]*)})'
  const rxUrl = '(\\S+)'
  const rx = new RegExp(`^${rxMethod}\\s+${rxUrl}(?:\\s+${rxParams})?$`, 'i')
  const details = action.match(rx)

  if (!details) throwMalformedActionError(action)

  onMethod(details[1].toLowerCase())

  onUrlTemplate(details[2])

  if (details[3]) {
    const method = details[1]
    if (method === 'GET') throwRequiredParametersInGetRequestError(action)
    onRequiredParameters(
      details[3]
        .split(',')
        .map(name => name.trim())
    )
  }
}

function throwMalformedActionError(action) {
  throw `Malformed action "${action}". Actions must be of the format "$method $urlWithEmbeddedVariableSlots" or "$method {$required, $param, $names} $urlWithEmbeddedVariableSlots"`
}
function throwRequiredParametersInGetRequestError(action) {
  throw `GET actions cannot use req.body to transport payload. Use url parameters instead. Malformed action "${action}"`
}
