import PageNotFound from '@/components/site/PageNotFound.vue'
import accountRoutes from '@/router/routes/accountRoutes.js'
// import learningMaterialsRoutes from '@/router/routes/learningMaterialsRoutes.js'
import config from '../../../../config.js'
import Home from '@/components/site/home/Home.vue'
import { isString } from '@/lib/getVariableType.js'
import path from 'path'

const { ROUTING } = config

const defaultRoutes = {
  Home: {
    path: '/',
    name: 'home',
    component: Home,
    props: true
  },
  About: {
    isMenuRoute: true,
    path: '/about',
    name: 'about',
    component: () => import ("@/components/site/about/About.vue"),
    props: true
  },
  News: {
    isMenuRoute: true,
    path: '/news',
    name: 'news',
    component: () => import("@/components/site/news/News.vue"),
    props: true
  },
  Testimonials: {
    isMenuRoute: true,
    path: '/testimonials',
    name: 'testimonials',
    component: () => import("@/components/site/testimonials/Testimonials.vue"),
    props: true
  },
  Resources: {
    isMenuRoute: true,
    path: '/resources',
    name: 'resources',
    component: () => import("@/components/site/resources/Resources.vue"),
    props: true
  },
  Services: {
    isMenuRoute: true,
    path: '/services',
    name: 'services',
    component: () => import("@/components/site/centerServices/Services.vue"),
    props: true
  },
}
const getRoute = (config, isMenuRoute) => {
  const route = { ...config }
  const componentIsPath = isString(route.component)
  if (componentIsPath) route.component = () => import(path.resolve(config.component))
  if (isMenuRoute) route.isMenuRoute = true
  return route
}

const hydrateRoutes = configObj => Object.values(configObj)
  .reduce(
    (list, config) => {
      const isNestedConfig = config.component === undefined
      const isRoute = config.name?.length
      const { isMenuRoute } = config
      if (isNestedConfig) Object.values(config)
        .filter(config => config.component)
        .forEach(config =>
          list.push(getRoute(config, isMenuRoute))
        )
      else if (isRoute) list.push(getRoute(config))
      return list
    },
    []
  )

export const getMenuRoutes = () => {
  /*
    menuRoutes: {
      key: { // config
        title: path
      }
    }
  */
  const configObj = ROUTING || defaultRoutes

  let routes = Object.fromEntries(
    Object.entries(configObj) // name, routes
      .filter(([, route]) => route.isMenuRoute)
      .map(([title, path]) => {
        delete path.isMenuRoute
        return [title, getRoute(path)]
      })
  )
  return routes
}

const serviceRoutes = hydrateRoutes(ROUTING || defaultRoutes)
console.log('service routes', serviceRoutes)

const routes = [
  ...serviceRoutes,
  ...accountRoutes,
  // ...learningMaterialsRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: PageNotFound
  }
]

export function getRouteByName(name) {
  name = name.toLowerCase()
  return routes.find(route => route.name.toLowerCase() === name)
}

export default routes
