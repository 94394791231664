import { act, sync } from '../../lib/APIRequest'
import {
  CREATE_COLLECTION,
  CREATE_DOCUMENT,
  CREATE_DOCUMENT_VERSION,
  CREATE_SERVICE,
  DELETE_COLLECTION,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_VERSION,
  DELETE_SERVICE,
  GET_DOCUMENTS,
  GET_COLLECTIONS,
  GET_DOCUMENT_VERSION_BY_PATH,
  GET_DOCUMENT_VERSIONS,
  LIST_SERVICES,
  LOAD_COLLECTION,
  LOAD_DOCUMENT,
  LOAD_DOCUMENT_BY_PATH,
  LOAD_DOCUMENT_VERSION,
  LOAD_SERVICE,
  PATCH_VERSION_ATTRIBUTE,
  PUT_COLLECTION_ATTRIBUTE,
  PUT_DOCUMENT_ATTRIBUTE,
  PUT_VERSION_ATTRIBUTE,
  PUT_SERVICE_ATTRIBUTE, GET_OR_CREATE_DOCUMENT, GET_OR_CREATE_COLLECTION
} from '@/store/graph/operations'

export default {
  [LIST_SERVICES]: (context, payload) => sync(context, LIST_SERVICES, payload),
  [CREATE_SERVICE]: (context, payload) => sync(context, CREATE_SERVICE, payload),
  [LOAD_SERVICE]: (context, payload) => sync(context, LOAD_SERVICE, payload),
  [PUT_SERVICE_ATTRIBUTE]: (context, payload) => sync(context, PUT_SERVICE_ATTRIBUTE, payload),
  [DELETE_SERVICE]: (context, payload) => sync(context, DELETE_SERVICE, payload),
  [CREATE_COLLECTION]: (context, payload) => sync(context, CREATE_COLLECTION, payload),
  [GET_COLLECTIONS]: (context, payload) => sync(context, GET_COLLECTIONS, payload),
  [GET_OR_CREATE_COLLECTION]: (context, payload) => sync(context, GET_OR_CREATE_COLLECTION, payload),
  [GET_OR_CREATE_DOCUMENT]: (context, payload) => sync(context, GET_OR_CREATE_DOCUMENT, payload),
  [LOAD_COLLECTION]: (context, payload) => sync(context, LOAD_COLLECTION, payload),
  [PUT_COLLECTION_ATTRIBUTE]: (context, payload) => sync(context, PUT_COLLECTION_ATTRIBUTE, payload),
  [DELETE_COLLECTION]: (context, payload) => sync(context, DELETE_COLLECTION, payload),
  [CREATE_DOCUMENT]: (context, payload) => sync(context, CREATE_DOCUMENT, payload),
  [GET_DOCUMENTS]: (context, payload) => sync(context, GET_DOCUMENTS, payload),
  [LOAD_DOCUMENT]: (context, payload) => sync(context, LOAD_DOCUMENT, payload),
  [LOAD_DOCUMENT_BY_PATH]: (context, payload) => sync(context, LOAD_DOCUMENT_BY_PATH, payload),
  [PUT_DOCUMENT_ATTRIBUTE]: (context, payload) => sync(context, PUT_DOCUMENT_ATTRIBUTE, payload),
  [DELETE_DOCUMENT]: (context, payload) => sync(context, DELETE_DOCUMENT, payload),
  [GET_DOCUMENT_VERSIONS]: (context, payload) => sync(context, GET_DOCUMENT_VERSIONS, payload),
  [CREATE_DOCUMENT_VERSION]: (context, payload) => sync(context, CREATE_DOCUMENT_VERSION, payload),
  [LOAD_DOCUMENT_VERSION]: (context, payload) => sync(context, LOAD_DOCUMENT_VERSION, payload),
  [PUT_VERSION_ATTRIBUTE]: (context, payload) => sync(context, PUT_VERSION_ATTRIBUTE, payload),
  [PATCH_VERSION_ATTRIBUTE]: (context, payload) => act(context, PATCH_VERSION_ATTRIBUTE, payload),
  [DELETE_DOCUMENT_VERSION]: (context, payload) => sync(context, DELETE_DOCUMENT_VERSION, payload),
  [GET_DOCUMENT_VERSION_BY_PATH]: (context, payload) => sync(context, GET_DOCUMENT_VERSION_BY_PATH, payload),

}
