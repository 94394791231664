import router                from '../../../../router'
import { popLoginRequired }  from '@/components/use/popups/popupMessages'
import getLoginRedirectRoute from '@/router/getLoginRedirectRoute'

const errorHandlers = {
  JsonWebTokenExpiredAndGracePeriodPassedError() {
    popLoginRequired()
    redirectToLogin()
  },
  UserNotFoundByTokenError() {
    popLoginRequired()
    redirectToLogin()
  },
  JsonWebTokenVerificationError() {
    popLoginRequired()
    redirectToLogin()
  },
  RequesterNotIdentifiableByTokenError() {
    popLoginRequired()
    redirectToLogin()
  },
  RequesterIdRequiredError() {
    popLoginRequired('Please Login do perform this action.')
    redirectToLogin()
  },
}

export function handleErrorResponse({
                                      err,
                                      action,
                                      re,
                                      context = {},
                                      payload,
                                    }) {
  let errorName = err.error?.name ?? err.name ?? err.error?.message ?? 'Server Error'
  if (errorName) {
    errorName = errorName.split('__')[0]
  }
  const handlerExists = Object.keys(errorHandlers).includes(errorName)

  if (handlerExists) {
    errorHandlers[errorName]()
  } else {
    console.log(993, err, action, re, context, payload)
    throw new Error(`Server Error: ${ errorName }`)
  }
}

function redirectToLogin() { // outsource to router
  const route = router.currentRoute.value
  const isAtLoginRoute = route.path.includes('authenticate/login')
  if (!isAtLoginRoute) {
    const loginAndRedirectRoute = getLoginRedirectRoute(route.path)
    router.push(loginAndRedirectRoute)
  }
}

// function assembleServerError(err, action, re, context, payload) {
//   /*
//   {
//     request: getRequestDetails(req),
//     error: {
//       ...err,
//       message: err.message,
//     },
//     context: {
//       // selected req.nab.names
//     }
//   }
//    */
//   console.log('server error', err)
//
//   const errorName = err.error?.name ?? ''
//   const serverError = {
//     actionName: Object.keys(actions)
//         .find(name => actions[name] === action),
//     name: errorName.replace(/__/g, '.'),
//     message: err.error.message,
//     params: {
//       // other error parameters
//     },
//
//     serverContext: {
//       httpRequest: err.request,
//       context: err.context,
//     },
//
//     clientContext: {
//
//       action,
//       actionPayload: payload,
//       ...context,
//     }
//   }
//
//   // error object parameters
//   Object.entries(err.error)
//       .filter(([name]) => {
//         const isParam = !['name', 'message'].includes(name)
//         return isParam
//       })
//       .map((name, value) => {
//         serverError.params[name] = value
//       })
//
//   return serverError
// }
