export * from './serviceAsset'
export * from './asset'
export * from './componentConfiguration'
export * from './extension'
export * from './person'

/*
  CLIENT-INTERNAL INTER-COMPONENT MUTATIONS
 */

export const SET_SERVICENAME =          'SET_SERVICENAME'
export const CONTINUE_SESSION =               'CONTINUE_SESSION             {token?}'
export const INITIALIZE_COLLECTION =          'INITIALIZE_COLLECTION'
export const SET_PENDING_REQUEST =            'SET_PENDING_REQUEST'
export const RESET_PENDING_REQUEST =          'RESET_PENDING_REQUEST'

/*
  CLIENT-INTERNAL MUTABLE STATES
 */

export const UPDATE_STATE =                   'UPDATE_STATE'
export const UPDATE_COLLECTION =              'UPDATE_COLLECTION'
export const UPDATE_ASSET =                   'UPDATE_ASSET'

export const PUT_DARKMODE =                   'PUT_DARKMODE'

export const CREATE_PRISTINE_STATE =          'CREATE_PRISTINE_STATE'
export const SET_INTERMEDIATE_ATTRIBUTE_VALUE =          'SET_INTERMEDIATE_ATTRIBUTE_VALUE'
export const RESTORE_PRISTINE_STATE =         'RESTORE_PRISTINE_STATE'
export const DELETE_PRISTINE_STATE =          'DELETE_PRISTINE_STATE'

export const PUT_SERVER_ERROR =               'PUT_SERVER_ERROR'
export const PUT_CLIENT_ERROR =               'PUT_CLIENT_ERROR'
export const DISMISS_ERROR =                  'DISMISS_ERROR'

export const POPUP_MESSAGE =                  'POPUP_MESSAGE                    {text, icon, expiresIn}'
export const DISMISS_POPUP =                  'DISMISS_POPUP                    {id}'

export const PUT_SHOW_FEEDBACK_DIALOG =       'PUT_SHOW_FEEDBACK_DIALOG         {value}'
export const PUT_WINDOW_DIMENSIONS =          'PUT_WINDOW_DIMENSIONS            {value}'
export const PUT_SCREEN_ORIENTATION =         'PUT_SCREEN_ORIENTATION           {value}'
