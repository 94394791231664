import { createWebHashHistory, createRouter } from 'vue-router'
import routes                                 from './routes'

const router = createRouter({
  history: createWebHashHistory('/'),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      setTimeout(() => {
        const elementId = encodeURIComponent(to.hash.substring(1))
        const element = document.getElementById(elementId)
        if (element) {
          window.scrollTo({
            top: getScrollOffset(element,40),
            behavior: 'smooth'
          })
        }
      }, 1000)
      return {top: 0}
    }
    else if (savedPosition) {
      return savedPosition
    }
    return {top: 0}
  }
})
export default router

function getScrollOffset(element, offset) {
  const elementPosition = element.getBoundingClientRect().top
  return elementPosition - offset
}
