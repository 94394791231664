import tokens from '../../../../../server/services/actions/person/lib/tokens'

const { abandonEmail, validateChangedEmail, validateEmail, resetPassphrase } = tokens

export const REGISTER_USER =                                "POST   :apiPath/person                                               {email, username, passphrase}"
export const LOGIN =                                        "POST   :apiPath/person/:username/login                               {passphrase}"
export const RESET_PASSWORD =                               "POST   :apiPath/person/forgotPassword                                {email}"
export const VALIDATE_RESET_PASSPHRASE_TOKEN =              `GET    :apiPath/person/${resetPassphrase.serverUrlPart}/:${resetPassphrase.tokenName}           {}`
export const PUT_NEW_PASSPHRASE =                           `PUT    :apiPath/person/${resetPassphrase.serverUrlPart}/:${resetPassphrase.tokenName}           {passphrase}`
export const VALIDATE_EMAIL =                               `GET    :apiPath/person/${validateEmail.serverUrlPart}/:${validateEmail.tokenName}               {}`
export const CONFIRM_CHANGING_EMAIL =                       `GET    :apiPath/person/${abandonEmail.serverUrlPart}/:${abandonEmail.tokenName}                 {}`
export const VALIDATE_CHANGED_EMAIL =                       `POST   :apiPath/person/${validateChangedEmail.serverUrlPart}/:${validateChangedEmail.tokenName} {passphrase}`
export const LOGOUT =                                       "POST   :apiPath/person/:username/logout                              {}"
export const CHECK_USERNAME_EXISTS =                        "HEAD   :apiPath/person/:username                                     {passphrase}"
export const GET_ACCOUNT_BY_USERNAME =                      "GET    :apiPath/person/:username                                     {}"
export const PUT_ACCOUNT_EMAIL =                            "PUT    :apiPath/person/:username/email                               {newEmail}"
export const PUT_ACCOUNT_PASSPHRASE =                       "PUT    :apiPath/person/:username/passphrase                          {oldPassphrase, passphrase}"
export const DELETE_ACCOUNT =                               "DELETE :apiPath/person/:username                                     {passphrase}"

export const RECRUIT_PERSON_AS_TECH_EXPERT =                "POST   :apiPath/person/:username/InviteAsTechExpert                  {message, assetUrl}"
export const RECRUIT_PERSON_AS_USE_CONTEXT_EXPERT =         "POST   :apiPath/person/:username/InviteAsUseContextExpert            {message, assetUrl}"
