export default {
  src: 'https://raw.githubusercontent.com/centre-for-humanities-computing/websiteMedia/main/images/CHCAA/Logo.png',
  alt: 'Missing image provided by the Ministry of Re-Shelving',
  zoom: 1,
  offsetHorizontalMin: 0,
  offsetHorizontal: 5,
  offsetHorizontalMax: 10,
  offsetVerticalMin: 0,
  offsetVertical: 5,
  offsetVerticalMax: 10,
  dataType: 'ZoomableImage',
}
