import * as actions from '../operations'
import { sync }     from "@/store/lib/APIRequest"

export default {
  [actions.LOGIN]:        (context, payload) => {
    localStorage.removeItem('Authorization')
    return sync(context, actions.LOGIN, payload)
  },
  [actions.LOGOUT]:       (context, payload) => {
    const promise = sync(context, actions.LOGOUT, payload)
      .then((result) => {
        return result
      })
    context.commit(actions.LOGOUT)
    return promise
  },
  [actions.REGISTER_USER]:    (context, payload) => sync(context, actions.REGISTER_USER, payload),
  [actions.VALIDATE_EMAIL]:    (context, payload) => sync(context, actions.VALIDATE_EMAIL, payload),
  [actions.CONFIRM_CHANGING_EMAIL]:    (context, payload) => sync(context, actions.CONFIRM_CHANGING_EMAIL, payload),
  [actions.VALIDATE_CHANGED_EMAIL]:    (context, payload) => sync(context, actions.VALIDATE_CHANGED_EMAIL, payload),
  [actions.RESET_PASSWORD]:    (context, payload) => sync(context, actions.RESET_PASSWORD, payload),
  [actions.VALIDATE_RESET_PASSPHRASE_TOKEN]:    (context, payload) => sync(context, actions.VALIDATE_RESET_PASSPHRASE_TOKEN, payload),
  [actions.PUT_NEW_PASSPHRASE]:    (context, payload) => sync(context, actions.PUT_NEW_PASSPHRASE, payload),
  [actions.PUT_ACCOUNT_EMAIL]:   (context, payload) => sync(context, actions.PUT_ACCOUNT_EMAIL, payload),
  [actions.PUT_ACCOUNT_PASSPHRASE]:   (context, payload) => sync(context, actions.PUT_ACCOUNT_PASSPHRASE, payload),
  [actions.DELETE_ACCOUNT]:  (context, payload) => sync(context, actions.DELETE_ACCOUNT, payload),
  [actions.CHECK_USERNAME_EXISTS]: (context, payload) => sync(context, actions.CHECK_USERNAME_EXISTS, payload),
  [actions.GET_ACCOUNT_BY_USERNAME]: (context, payload) => sync(context, actions.GET_ACCOUNT_BY_USERNAME, payload),

  [actions.RECRUIT_PERSON_AS_TECH_EXPERT]: (context, payload) => sync(context, actions.RECRUIT_PERSON_AS_TECH_EXPERT, payload),
  [actions.RECRUIT_PERSON_AS_USE_CONTEXT_EXPERT]: (context, payload) => sync(context, actions.RECRUIT_PERSON_AS_USE_CONTEXT_EXPERT, payload),
}
