import * as actions                                from "../operations"
import { updateStoreState }                        from "@/store/lib/storeHelpers/storeHelpers"
import { getStoredToken, removeToken, storeToken } from "@/store/graph/mutations/lib/localStorage"
import getPersonFromToken                          from "@/store/graph/mutations/lib/getPersonFromToken"

export default {
  [actions.CONFIRM_CHANGING_EMAIL]: function () { /* handled in source component */ },
  [actions.CONTINUE_SESSION]: (state, token) => {
    if (token) storeToken(token)
    else token = getStoredToken()
    let user = getPersonFromToken(token)
    state.user = user
  },
  [actions.DELETE_ACCOUNT]: function (state) {
    state.user = null
  },
  [actions.GET_ACCOUNT_BY_USERNAME]: (state, action) => {
    updateStoreState(state, action)
  },
  [actions.LOGIN]: (state, action) => {
    state.user = action.response.person[0]
  },
  [actions.LOGOUT]: function (state) {
    removeToken()
    state.user = undefined
  },
  [actions.PUT_ACCOUNT_EMAIL]: function (state, action) {
    state.user.email = action.response
  },
  [actions.PUT_ACCOUNT_PASSPHRASE]: function () { /* handled in source component */ },
  [actions.PUT_NEW_PASSPHRASE]: function (state, action) {
    state.user = action.response.person[0]
  },
  [actions.REGISTER_USER]: function () { /* handled in source component */ },
  [actions.RESET_PASSWORD]: function () { /* handled in source component */ },
  [actions.VALIDATE_CHANGED_EMAIL]: function (state, action) {
    state.user = action.user
  },
  [actions.VALIDATE_EMAIL]: function (state, action) {
    state.user = action.user
  },
  [actions.VALIDATE_RESET_PASSPHRASE_TOKEN]: function () { /* handled in source component */ },

}
