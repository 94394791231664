import { RESET_PENDING_REQUEST, SET_PENDING_REQUEST } from '@/store/graph/operations'

export default {
  [SET_PENDING_REQUEST](state, requestId) {
    state.pendingRequest[requestId] = Date.now()
  },
  [RESET_PENDING_REQUEST](state, requestId) {
    delete state.pendingRequest[requestId]
  },
}
