export const ASSET_BY_PARENTID = 'ASSET_BY_PARENTID'
export const ASSET_BY_REQUESTID = 'ASSET_BY_REQUESTID'
export const TECHDOMAINS_BY_PARENTID = 'TECHDOMAINS_BY_PARENTID'
export const CLAUSES_BY_CONTRACTID = 'CLAUSES_BY_CONTRACTID'

const itemByAttributeValue = (item, attributeName, value) => item[attributeName] === value
const itemByParentId = (item, parentId, parentIdAttributeName) => itemByAttributeValue(item, (parentIdAttributeName ?? 'parentId'), parentId)
const assetByParentId = state => (collectionName, parentId, parentIdAttributeName) => Object.values(state?.[collectionName]).filter(item => itemByParentId(item, parentId, parentIdAttributeName))

export default {
  [ASSET_BY_PARENTID]: assetByParentId,
  [TECHDOMAINS_BY_PARENTID]: state => parentId => assetByParentId(state)('techDomain', parentId),
  [CLAUSES_BY_CONTRACTID]: state => parentId => assetByParentId(state)('clause', parentId, 'contractId'),
}
