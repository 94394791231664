import Vuex from 'vuex'

import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import state from './state'

import colors from './colors'
import markdownIt from './markdownIt'

const debug = process.env.NODE_ENV !== "production";

const store = Vuex.createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    colors,
    markdownIt,
  },
  strict: debug
})

export default store
