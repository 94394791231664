
class Action {
  action
  payload

  // watchdog for response > timeout : try again later OR throw error
  constructor(action, payload) {
    this.action = action
    this.payload = payload
  }

  async call() {
    return new Promise(function (resolve, reject) {
      try {
        resolve(this.payload.data?.value ?? '')
      } catch (e) {
        reject(e)
      }
    }.bind(this))
      .then(() => {return this.action(this.payload)})
      .catch(e => console.log('throttled queue error', e))
  }
  setPayload(payload) {this.payload = payload}
}

class RequestDriver {
  delay = 3000
  alias
  action
  nextAction
  nextPayload
  promise
  resolver
  rejecter
  requesterPromise
  constructor(alias) {
    this.alias = alias
    this.promise = null
  }

  getNewPromise() {
    this.promise = new Promise((resolve, reject) => {
      this.resolver = resolve
      this.rejecter = reject
    })
    this.startCooldownTimer()
    return this.promise
  }

  startCooldownTimer() {
    setTimeout(() => {
      this.resolver()
      this.promise = null
      this.action = null
    }, this.delay)
  }

  createNextAction(action, payload) {
    if (this.nextAction) {
      this.nextAction.setPayload(payload)
    } else {
      this.nextAction = new Action(action, payload)
    }
  }

  queueAction(action, payload) {
    if (this.action != null) {
      this.createNextAction(action, payload)
      if (this.promise != null) {
       this. requesterPromise = this.promise
      } else {
        this.requesterPromise = this.getNewPromise().then(() => {return this.nextAction.call()})
      }
    }
    else {
      this.action = new Action(action, payload)
      this.requesterPromise = this.action.call().then(response => response)
    }
    return this.requesterPromise
  }
}


const requestDriverChannels = {}
export const throttledAction = (alias, action, payload) => { // fx. document.#id.content, axios, re
  const driver = getDriver(alias, action)
  return driver.queueAction(action, payload)
}

function getDriver(alias, action) {
  let driver = requestDriverChannels[alias]
  if (!driver && action) {
    driver = new RequestDriver(alias)
    requestDriverChannels[alias] = driver
  }
  return driver
}
