import Auth                      from "@/components/site/authentication/Authentication"
import Account                   from "@/components/site/Account"
import { requireAuthentication } from "@/router/requireAuthentication"
import tokens                    from '../../../../server/services/actions/person/lib/tokens'

const {
  abandonEmail,
  resetPassphrase,
  validateChangedEmail,
  validateEmail
} = tokens

export default [
  {
    path: `/authenticate/${ resetPassphrase.clientUrlPart }/:${ resetPassphrase.tokenName }`,
    name: 'resetpassphrase',
    component: () => import(/* webpackChunkName: "account" */ "../../components/site/authentication/ResetPassphrase"),
    props: true
  },
  {
    path: `/authenticate/${ validateEmail.clientUrlPart }/:${ validateEmail.tokenName }`,
    name: 'validateemail',
    component: () => import(/* webpackChunkName: "account" */ "../../components/site/authentication/ValidateEmail"),
    props: true
  },
  {
    path: `/authenticate/changeEmail/`,
    name: 'changeemail',
    component: () => import(/* webpackChunkName: "account" */ "../../components/site/authentication/ChangeEmail"),
    props: true
  },
  {
    path: `/authenticate/${ abandonEmail.clientUrlPart }/:${ abandonEmail.tokenName }`,
    name: 'confirmchangeemail',
    component: () => import(/* webpackChunkName: "account" */ "../../components/site/authentication/ConfirmChangingEmail"),
    props: true
  },
  {
    path: `/authenticate/${ validateChangedEmail.clientUrlPart }/:${ validateChangedEmail.tokenName }`,
    name: 'validatechangedemail',
    component: () => import(/* webpackChunkName: "account" */ "../../components/site/authentication/ValidateChangedEmail"),
    props: true
  },
  {
    path: '/authenticate/:activePage/:targetUrl(.*)?',
    name: 'authenticate',
    component: Auth,
    props: (route) => {
      return {
        activePage: (route.params?.activePage || 'login'),
        targetUrl: (route.params?.targetUrl || '/'),
      }
    }
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    props: true,
    beforeEnter: requireAuthentication
  },
  {
    path: '/UpdateAccountPassphrase',
    name: 'updateaccountpassphrase',
    component: () => import(/* webpackChunkName: "account" */ "@/components/site/authentication/UpdateAccountPassphrase"),

    props: true,
    beforeEnter: requireAuthentication
  },
]
