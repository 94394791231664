import ColorService from '../lib/colorService'
import appState     from './graph/state'

const appColorTone = 'blue-grey'
const appColors = ColorService(appColorTone, 'app')

const state = {
  minFontSize: 80,
  maxFontSize: 150,
  appColorTone: appColorTone,
  appColors: appColors,
  pageColor: {
    highlight: appColors.lightAccent().color,
    offset: '#777777',
    background: '#86939d'
  },
  parchmentColorArray: ['#A1887F', '#FFD9A0', '#A1887F', '#A1887F', '#FFD9A0', '#A1887F'],
  avatarColorArray: ['#FFFFFF', '#777777'],
  ...appState,
}
export default state
