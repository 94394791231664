import { assertIsValidPatch } from '@/store/lib/APIRequest/lib/patch/assertIsValidPatch'

export const getPatchReference = patch => {
  assertIsValidPatch(patch)
  let path = ''
  if (patch.versionId) path += `service.${ patch.versionId }`
  else if (patch.documentId) path += `document.${ patch.documentId }`
  else if (patch.collectionId) path += `collection.${ patch.collectionId }`
  else if (patch.serviceId) path += `version.${ patch.serviceId }`
  path += '.' + patch.attributeName
  return path
}
