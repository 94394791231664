import { actionExists }                  from '@/store/lib/APIRequest/lib/actionExists'
import { isPristine, matchesStoreValue } from '@/store/graph/mutations/lib/internalMutations/APIRequests'
import {
  CREATE_PRISTINE_STATE,
  DELETE_PRISTINE_STATE, RESTORE_PRISTINE_STATE,
  SET_INTERMEDIATE_ATTRIBUTE_VALUE
}                            from '@/store/graph/operations'
import { getPatchReference } from '@/store/lib/APIRequest/lib/patch/actionReference'
import { throttledAction }   from '@/store/lib/APIRequest/lib/debouncedQueue/throttledQueue'
import axios                     from 'axios'
import { handleErrorResponse }   from '@/store/lib/APIRequest/lib/handleErrorResponses'
import { maskSensitiveData }     from '@/store/lib/APIRequest/lib/maskSensitiveData'
import { popError }              from '@/components/use/popups/popupMessages'
import { loadContextVariables }  from '@/store/lib/APIRequest/lib/requestHelpers/loadContextVariables'
import { getAxiosRequestObject } from '@/store/lib/APIRequest/lib/requestHelpers/getAxiosRequestObject'
import { handleJwt }             from '@/store/lib/APIRequest/lib/handleJWT'

/**
 * mutates local state immediately for responsive ActionRequestAdapter
 * checks later if server response matches, rolls back if not
 *
 *  fx. fast edits of text fields
 */
export function act({ commit, state }, action, patch) {
  if (!actionExists(action)) throw `APIRequest.act ERROR unknown action "${ action }"`
  if (isPristine(state, patch)) commit(CREATE_PRISTINE_STATE, patch)

  commit(SET_INTERMEDIATE_ATTRIBUTE_VALUE, patch)

  const reference = getPatchReference(patch)
  const re = getPatchActionRequest(state, commit, action, patch)

  return throttledAction(reference, axios, re) // promise
    .then(result => {
      const requestWentLive = !!result
      if (requestWentLive) {
        // todo: use handleAxiosResponse
        onActResponse({
          state,
          action,
          re,
          commit,
          patch,
          result
        })
      }
      return result
    })
    .catch(err => { // todo: error detection (404)
      onActError(err, commit, action, re, patch)
    })
}

function getPatchActionRequest(state, commit, action, patch) {
  const context = loadContextVariables(state, patch)
  return getAxiosRequestObject(action, context)
}

function onActResponse({
                         state,
                         commit,
                         action,
                         re,
                         patch,
                         result
                       }) {
  const isAxiosPromise = result.data
  if (result.data?.error) {
    handleErrorResponse({
      err: result.data,
      action,
      re,
      payload: { patch },
      commit
    })
  } else if (isAxiosPromise) {
    if (result.headers?.['authorization']) handleJwt(commit, result.headers['authorization'])
    const value = result.data.patchResult
    if (matchesStoreValue(state, patch, value)) commit(DELETE_PRISTINE_STATE, patch)
  }
}

function onActError(err, commit, action, re, patch) {
  console.log('actError. restoring __pristine state.', 'action:', action, maskSensitiveData(re), err)
  commit(RESTORE_PRISTINE_STATE, patch)
  popError({
    text: err.message,
  })
}
