const deleteUndefinedProperties = obj => Object.keys(obj).forEach(key => {
  if (obj[key] === undefined) delete obj[key]
})

export function loadContextVariables(state, context) {
  if (state.user) {
    context = {
      ...context,
      ...state.user,
    }
  }
  deleteUndefinedProperties(context)

  return context
}
