import * as actions from "../../../operations"

export default {
  [actions.PUT_WINDOW_DIMENSIONS]: function (state, value) {
    state.__windowDimensions = value
    const orientation = value.width > value.height ? 'landscape' : 'portrait'
    state.__screenOrientation = orientation
  },
  [actions.PUT_SCREEN_ORIENTATION]: function (state, value) {
    state.__screenOrientation = value
  },
  [actions.PUT_DARKMODE]: function (state, value) {
    state['darkmode'] = value
    localStorage.setItem('darkmode', value)
  },
}
