import { NABServerError } from '../../../../../../../server/services/errors'

export const assertIsValidPatch = patch => {
  !patch.serviceId && throw new PatchRequiresServiceIdError(patch)
  patch.documentId && !patch.collectionId && throw new DocumentPatchRequiresCollectionIdError(patch)
  patch.versionId && !patch.documentId && throw new VersionPatchRequiresDocumentIdError(patch)
  !patch.attributeName && throw new PatchRequiresAttributeNameError(patch)
}

class PatchRequiresServiceIdError extends NABServerError {
  constructor(patch) {
    super('PatchRequiresServiceIdError', patch)
  }
}

class DocumentPatchRequiresCollectionIdError extends NABServerError {
  constructor(patch) {
    super('DocumentPatchRequiresCollectionIdError', patch)
  }
}

class VersionPatchRequiresDocumentIdError extends NABServerError {
  constructor(patch) {
    super('VersionPatchRequiresDocumentIdError', patch)
  }
}

class PatchRequiresAttributeNameError extends NABServerError {
  constructor(patch) {
    super('PatchRequiresAttributeNameError', patch)
  }
}
