import routes       from "@/router/routes"
import { useRoute } from 'vue-router'

export default function(targetUrl) {
  if (!targetUrl) {
    const route = useRoute()
    targetUrl = route.value.path ?? '/'
  }
  const loginRoute = routes.find(route => route.name === 'authenticate')
  return loginRoute.path
    .replace(':activePage', 'login')
    .replace('/:targetUrl(.*)?', targetUrl)
}
