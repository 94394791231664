import { loadContextVariables }  from '@/store/lib/APIRequest/lib/requestHelpers/loadContextVariables'
import { getAxiosRequestObject } from '@/store/lib/APIRequest/lib/requestHelpers/getAxiosRequestObject'
import requestSlot               from '@/store/lib/APIRequest/lib/debouncedQueue/slottedRequest'
import axios                     from 'axios'
import { handleErrorResponse }   from '@/store/lib/APIRequest/lib/handleErrorResponses'
import { maskSensitiveData }     from '@/store/lib/APIRequest/lib/maskSensitiveData'
import { popError }              from '@/components/use/popups/popupMessages'
import { handleJwt }             from '@/store/lib/APIRequest/lib/handleJWT'
import { actionExists }          from '@/store/lib/APIRequest/lib/actionExists'

/**
 * awaits response before it mutates the local state
 *
 *  fx. create new objects, security-related functionality
 */
export function sync({ commit, state }, action, payload = {}) {
  if (!actionExists(action)) throw `APIRequest.sync ERROR unknown action "${ action }"`

  const context = loadContextVariables(state, payload)
  const re = getAxiosRequestObject(action, context)

  const actionId = `${ re.method } ${ re.url }`
  if (requestSlot.isIdle(actionId)) {
    requestSlot.setPending(actionId)
    return sendRequest({
      action,
      re,
      context,
      payload,
      commit,
    })
      .then(result => {
        requestSlot.reset(actionId)
        return result
      })
  } else {
    console.log(41, 'request is busy. denied.', re.method, re.url)
  }
}


function sendRequest({
                       action,
                       re,
                       context,
                       payload,
                       commit,
                     }) {
  return axios(re)
    .then(result => { // todo: use handleAxiosResponse()
      if (result.data?.error) {
        handleErrorResponse({
          err: result.data,
          action,
          re,
          context,
          payload,
          commit
        })
      } else {
        try {
          handleSyncResponse(commit, action, context, result)
        } catch (err) {
          handleMutationError(err, action, re, commit)
        }
      }
      return result.data
    })
    .catch(err => {
      handleActionError(err, action, re, context, payload, commit)
    })
}

function handleSyncResponse(commit, action, context, result) {
  if (result.headers['authorization']) handleJwt(commit, result.headers['authorization'])
  commit(action, {
    name: action,
    context,
    response: result.data
  })
}


function showError(err, prefix, re) {

  console.warn('mutation error')
  console.warn(err)
  console.warn('re', re)

  console.log(maskSensitiveData(re))
  const code = err.code ? ` (${ err.code })` : ''
  const errorMessage = `# store.${ prefix }.${ err.name }${ code }:
<pre>${ err.message }</pre>
`
  popError(errorMessage)

}

function handleMutationError(err, action, re, commit) {
  showError(err, 'mutation', re, commit)
}

function handleActionError(err, action, re, context, payload) {
  console.warn('action', action)
  console.warn('re', re)
  console.warn('context', context)
  console.warn('payload', payload)

  console.error(err)
  popError(`# action.${ err.name || 'Action Error' }
  ${ err.message }`)
}
