import assetMutations    from './asset'
import serviceAssetMutations    from './serviceAsset'
import personMutations    from './person'
import _internalMutations from './lib/internalMutations'

export default {
  ...assetMutations,
  ...serviceAssetMutations,
  ...personMutations,
  ..._internalMutations,
}
