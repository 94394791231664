import { isObject }  from '../../../../../lib/getVariableType'
import { mergeDeep } from '../../../../../lib/mergeDeep'

export function updateStoreState(state, action) {
  const isStateUpdate = isObject(action.response)
  const isPatchUpdate = !isStateUpdate && action.response.patchResult
  if (isStateUpdate) {
    // action.response: { collectionName: [ assetUpdate, ...]}
    Object.entries(action.response)
      .forEach(([collectionName, updates]) => {
        // collectionUpdates: [ assetUpdate, ...]}
        updateCollection(state, { collectionName, updates })
      })
  } else if (isPatchUpdate) {
    // console.log(44, action)
  }
}

export function updateCollection(state, {
  collectionName,
  updates
}) {
  !state && throw new MissingRequiredParameterError('state', 'state is required')
  !collectionName && throw new MissingRequiredParameterError('collectionName', 'collectionName is required')
  !updates && throw new MissingRequiredParameterError('updates', 'updates array is required')
  for (let i = 0; i < updates.length; i++) {
    // assetUpdate: asset
    const update = updates[i]
    updateAsset(state, {
      collectionName,
      repoId: update.repoId,
      update
    })
  }
}

export function updateAsset(state, {
  collectionName,
  repoId,
  update
}) {
  !state && throw new MissingRequiredParameterError('state', 'state is required')
  !collectionName && throw new MissingRequiredParameterError('collectionName', 'collectionName is required')
  !repoId && throw new MissingRequiredParameterError('repoId', 'repoId is required')

  const updateKeys = Object.keys(update)
  const isDeleteToken = updateKeys[0] === 'repoId' && updateKeys.length === 1

  if (isDeleteToken) delete state[collectionName][update.repoId]
  else {
    state[collectionName][repoId] = state[collectionName][repoId] ?? {}
    mergeDeep(state[collectionName][repoId], update)
  }
}

class MissingRequiredParameterError {
  constructor(name, message) {
    this.name = name
    this.message = message
  }
}
