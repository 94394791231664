import users from './users'
import errors from './errors'
import loci             from './loci'
import siteDocuments             from './siteDocuments'
import componentConfiguration             from './componentConfiguration'
import serviceAssets  from './serviceAssets'
import pendingRequest from '@/store/graph/defaults/pendingRequest'
import config from '../../../../../config.js'

const {
  SERVICE_ACRONYM
} = config

export default {
  ...pendingRequest,
  ...serviceAssets,
  ...loci,
  ...siteDocuments,
  ...componentConfiguration,
  ...users,
  ...errors,
  // internal
  serviceName: SERVICE_ACRONYM, // for SPAs
  extension: { /* extensionServiceName: { instanceName: instance } */ },
  showFeedbackDialog: false,
  __windowDimensions: {
    width: null,
    height: null
  },
  __screenOrientation: '',
  __pristine: {},
}
