import * as actions from "../../../operations"

export default {

  [actions.PUT_SERVER_ERROR]: function (state, serverError) {
    state.serverErrors[serverError.actionName] = serverError
    state.serverErrors.last = serverError
  },
  [actions.PUT_CLIENT_ERROR]: function (state, error) {
    state['clientErrors'][error.id] = error
  },
  [actions.DISMISS_ERROR]: function (state, type) {
    delete state[`serverErrors.${type}`]
  }
}
