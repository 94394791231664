
export function storeToken(token) {
  localStorage.setItem(`Authorization`, token)
}
export function getStoredToken() {
  return (localStorage.getItem('Authorization') ?? '')
}
export function removeToken() {
  localStorage.removeItem(`Authorization`)
}
