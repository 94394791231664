import {
  ASSERT_NEW_DOCUMENT_NAME_EXISTS,
  CREATE_COLLECTION,
  CREATE_DOCUMENT,
  CREATE_DOCUMENT_VERSION,
  CREATE_SERVICE,
  DELETE_COLLECTION,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_VERSION,
  DELETE_SERVICE,
  GET_DOCUMENTS,
  GET_COLLECTIONS,
  GET_DOCUMENT_VERSION_BY_PATH,
  GET_DOCUMENT_VERSIONS,
  LIST_SERVICES,
  LOAD_COLLECTION,
  LOAD_DOCUMENT,
  LOAD_DOCUMENT_BY_PATH,
  LOAD_DOCUMENT_VERSION,
  LOAD_SERVICE,
  PUT_VERSION_ATTRIBUTE,
  PATCH_VERSION_ATTRIBUTE,
  PUT_COLLECTION_ATTRIBUTE,
  PUT_SERVICE_ATTRIBUTE, GET_OR_CREATE_COLLECTION, GET_OR_CREATE_DOCUMENT
} from '../operations'
import {updateStoreState} from "../../lib/storeHelpers/storeHelpers"

export default {
  [ASSERT_NEW_DOCUMENT_NAME_EXISTS]: updateStoreState,
  [CREATE_COLLECTION]: updateStoreState,
  [GET_OR_CREATE_COLLECTION]: updateStoreState,
  [GET_OR_CREATE_DOCUMENT]: updateStoreState,
  [CREATE_DOCUMENT]: updateStoreState,
  [CREATE_DOCUMENT_VERSION]: updateStoreState,
  [CREATE_SERVICE]: updateStoreState,
  [DELETE_COLLECTION]: updateStoreState,
  [DELETE_DOCUMENT]: updateStoreState,
  [DELETE_DOCUMENT_VERSION]: updateStoreState,
  [DELETE_SERVICE]: updateStoreState,
  [GET_DOCUMENTS]: updateStoreState,
  [GET_COLLECTIONS]: updateStoreState,
  [GET_DOCUMENT_VERSION_BY_PATH]: updateStoreState,
  [GET_DOCUMENT_VERSIONS]: updateStoreState,
  [LIST_SERVICES]: updateStoreState,
  [LOAD_SERVICE]: updateStoreState,
  [LOAD_COLLECTION]: updateStoreState,
  [LOAD_DOCUMENT_BY_PATH]: updateStoreState,
  [LOAD_DOCUMENT]: updateStoreState,
  [LOAD_DOCUMENT_VERSION]: updateStoreState,
  [PUT_VERSION_ATTRIBUTE]: updateStoreState,
  [PATCH_VERSION_ATTRIBUTE]: updateStoreState,
  [PUT_SERVICE_ATTRIBUTE]: updateStoreState,
  [PUT_COLLECTION_ATTRIBUTE]: updateStoreState,
  [PUT_VERSION_ATTRIBUTE]: updateStoreState,
}
