import store                                          from '../../../../../store'
import { RESET_PENDING_REQUEST, SET_PENDING_REQUEST } from '@/store/graph/operations'

const setPending = id => isIdle(id) && store.commit(SET_PENDING_REQUEST, id)
const isBusy = (id, maxPendingTime = 5000) => {
  const requestTimestamp = store.state.pendingRequest[id]
  return requestTimestamp && requestTimestamp + maxPendingTime >= Date.now()
}
const isIdle = id => !isBusy(id)
const reset = id => store.commit(RESET_PENDING_REQUEST, id)
export default {
  setPending,
  reset,
  isBusy,
  isIdle,
}


