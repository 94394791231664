// SERVICES
export const LIST_SERVICES =            'GET    :apiPath/service/                   {}'
export const CREATE_SERVICE =           'POST   :apiPath/service/                   {serviceName}'

// SERVICE
export const LOAD_SERVICE =             'GET    :apiPath/service/:serviceName       {}'
export const PUT_SERVICE_ATTRIBUTE =    'PATCH  :apiPath/service/:serviceId         {attributeName, value}'
export const DELETE_SERVICE =           'DELETE :apiPath/service/:serviceId         {}'

// COLLECTIONS
export const CREATE_COLLECTION =        'POST   :apiPath/service/:serviceId         {collectionName, encoding?}'
export const GET_OR_CREATE_COLLECTION = 'POST   :apiPath/service/:serviceName/:collectionName                    {}'

// COLLECTION
export const GET_COLLECTIONS =          'GET    :apiPath/service/:serviceId/         {}'
export const LOAD_COLLECTION =          'GET    :apiPath/service/:serviceId/:collectionName                    {}'
export const PUT_COLLECTION_ATTRIBUTE = 'PUT    :apiPath/service/:serviceId/:collectionId                      {attributeName, value}'
export const DELETE_COLLECTION =        'DELETE :apiPath/service/:serviceId/:collectionId                      {}'

// ASSET COLLECTION ITEMS
export const CREATE_DOCUMENT =        'POST   :apiPath/service/:serviceId/:collectionId/doc                   {documentName, content?, versionName?}'
export const GET_OR_CREATE_DOCUMENT = 'POST   :apiPath/service/:serviceName/:collectionName/getOrCreateDocument   {documentName, content?, versionName?}'
export const GET_DOCUMENTS =          'GET    :apiPath/service/:serviceId/:collectionId/                      {}'

// ASSET COLLECTION ITEM
export const LOAD_DOCUMENT_BY_PATH =  'GET    :apiPath/service/:serviceName/coll/:collectionName/doc/:documentName         {}'
export const LOAD_DOCUMENT =          'GET    :apiPath/service/:serviceId/:collectionId/doc/:documentId         {}'
export const DELETE_DOCUMENT =        'DELETE :apiPath/service/:serviceId/:collectionId/doc/:documentId         {}'

// ITEM VERSIONS
export const GET_DOCUMENT_VERSIONS =    'GET    :apiPath/service/:serviceId/:collectionId/doc/:documentId/v              {}'
export const CREATE_DOCUMENT_VERSION =  'POST   :apiPath/service/:serviceId/:collectionId/doc/:documentId              {versionName?, encoding, content?}'

// ITEM VERSION
export const PUT_VERSION_ATTRIBUTE =    'PUT    :apiPath/service/:serviceId/:collectionId/doc/:documentId   {versionId?, attributeName, value}'
export const PATCH_VERSION_ATTRIBUTE =  'PATCH  :apiPath/service/:serviceId/:collectionId/doc/:documentId   {versionId?, attributeName, value}'
export const DELETE_DOCUMENT_VERSION =  'DELETE :apiPath/service/:serviceId/:collectionId/doc/:documentId   {versionId?}'

// export const ASSERT_NEW_DOCUMENT_NAME_EXISTS =    'HEAD :apiPath/service/:serviceId/:collectionId'
export const GET_DOCUMENT_VERSION_BY_PATH =      'GET    :apiPath/service/:serviceName/:collectionName/byPath/:documentName/:versionName   {}'
