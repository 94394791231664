import { createApp } from 'vue'
import App             from './App'
import router          from './router'
import VueSmoothScroll from 'vue3-smooth-scroll'
import Markdown from 'vue3-markdown-it'

import * as components      from './components'

import './lib/replaceAllShim.js'
import 'uikit/dist/js/uikit.min.js'
import 'uikit/dist/css/uikit.min.css'
import './styles/style.styl'

import '@/plugins/Dayjs' // ?

import store from './store'

const app = createApp(App)

Object.keys(components).forEach(name => {
  app.component(name, components[name])
})

app.use(Markdown)

app
  .use(router)
  .use(VueSmoothScroll)
  .use(store)
  .mount('#app')

