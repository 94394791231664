import store                 from "@/store"
import { popError }          from "@/components/use/popups/popupMessages"
import getLoginRedirectRoute from "@/router/getLoginRedirectRoute"
import getPersonFromToken    from "@/store/graph/mutations/lib/getPersonFromToken"

export function requireAuthentication(to, from, next) {
  const person = store.state.user || getPersonFromToken()
  const authenticatedUser = person?.username
  if (authenticatedUser) next()
  else handleAuthenticationError(to, next)
}

function handleAuthenticationError(to, next) {
  popError(`You need to login to load the page /${ to.name }`)
  next(getLoginRedirectRoute(to.fullPath))
}
