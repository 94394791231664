import vueConf from "../../../../../../vue.config.js"
import config from '../../../../../../../config.js'
import {withPrefix} from "../../../../../../../lib/stringFunctions";

const { apiPath, serverUrl } = config

export function getRequestUrl(template, data) {

  let sitePath = vueConf.publicPath ? withPrefix(vueConf.publicPath, '/') : ''
  let url = `${serverUrl}${sitePath}`
  data.apiPath = apiPath
  try {
    url += fillOutUrlTemplate(template, data)
  } catch (err) {
    throw `getActionRequestObject.${err}
action: "${template}"`

  }

  return url
}

const getParamValue = (context, name) => {

  assertNameIsDefined(context, name)
  assertNameIsNotReserved(context, name)

  let value = context[name]
  delete context[name]
  if (name !== 'apiPath') value = encodeURIComponent(value)
  return value
}

export function fillOutUrlTemplate(template, data) {
  return template
    .split('/')
    .map(name => {
      let part = name

      const isSlotName = name[0] === ':'
      if (isSlotName) part = getParamValue(data, name.substring(1))

      return part
    })
    .join('/')
}

function assertNameIsDefined(data, name) {
  data[name] === undefined && throwUndefinedVariableException(name)
}
function assertNameIsNotReserved(name) {
  const reservedProps = ['requestId']
  reservedProps.includes(name) && throwReservedParameterNameException(name) // todo: only in POST requests
}

function throwUndefinedVariableException(name) {
  throw `missingActionURLParameterError: url parameter "${name}" is required"`
}
function throwReservedParameterNameException(name) {
  throw `throwReservedParameterNameError: url parameter "${name}" is reserved"`
}
