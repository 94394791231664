export default {
  locus: {
    notAllBits: {
      name: 'notAllBits Library',
      techDomains: [],
      userConstraints: [],
      // ...
    }
  },
  techDomain: {},
  userConstraint: {},
  hookNames: [
    'PUBLICATION_AT_LOCUS',
    'PUBLICATION_BY_PERSON',
    'PUBLICATION_IN_TECHDOMAIN',
    'PUBLICATION_OF_COMPONENT',
  ],
}
