const path = require('path')
const {
  clientUrl,
  SERVICE_ACRONYM,
  sitePath,
} = require('../config.js')

module.exports = {
  devServer: {
    proxy: clientUrl
  },
  chainWebpack: config => {
    // inject stylus variables into modules
    const types = ['vue-modules', 'vue', 'normal-modules', 'normal']
    types.forEach(type => addStyleResource(config.module.rule('stylus').oneOf(type)))
    // apply app title in index.html

    config
        .plugin('html')
        .tap(args => {
          args[0].title = SERVICE_ACRONYM
          return args
        })
  },
  configureWebpack: {
    optimization: {
      splitChunks: {
        minSize: 10000,
        maxSize: 250000,
      }
    },
    resolve: {
      alias : {
        "@c": path.resolve("src/components"),
        "@ci": path.resolve("src/components/icons"),
        "@u": path.resolve("src/components/use"),
        "@": path.resolve('src'),
      },
      extensions: [
        ".vue"
      ]
    },
    watchOptions: {
      aggregateTimeout: 500,
      poll: 1000
    }
  },
  runtimeCompiler: true,
  publicPath: process.env.NODE_ENV === 'production'
    ? sitePath
    : '',
  crossorigin: "use-credentials"
}

function addStyleResource (rule) {
  rule.use('style-resource')
    .loader('style-resources-loader')
    .options({
      patterns: [
        path.resolve(__dirname, './src/styles/imports.styl'),
      ],
    })
}
