import { areSame }            from '../../../../../../../server/lib/objects/areSame'
import {
  CREATE_PRISTINE_STATE,
  DELETE_PRISTINE_STATE,
  RESTORE_PRISTINE_STATE,
  SET_INTERMEDIATE_ATTRIBUTE_VALUE
}                             from '../../../operations'
import {
  getPatchReference
}                             from '@/store/lib/APIRequest/lib/patch/actionReference'
import { assertIsValidPatch } from '@/store/lib/APIRequest/lib/patch/assertIsValidPatch'

export default {

  [CREATE_PRISTINE_STATE](state, patch) {
    const reference = getPatchReference(patch)
    state.__pristine[reference] = patch.value
  },

  [SET_INTERMEDIATE_ATTRIBUTE_VALUE](state, patch) {
    const reference = getPatchReference(patch)
    const [collectionName, documentId, attributeName] = reference.split('.')
    state[collectionName][documentId][attributeName] = patch.value
  },

  [RESTORE_PRISTINE_STATE](state, patch) {
    const reference = getPatchReference(patch)
    const pristineValue = state.__pristine[reference]
    state[reference] = pristineValue
    delete state.__pristine[reference]
  },

  [DELETE_PRISTINE_STATE](state, patch) {
    const reference = getPatchReference(patch)
    delete state.__pristine[reference]
  },
}

export const getPristineValue = (state, patch) => {
  const reference = getPatchReference(patch)
  return state.__pristine[reference]
}
export const isPristine = (state, patch) => {
  assertIsValidPatch(patch)
  const trackedValue = getPristineValue(state, patch)
  const patchValueIsValid = patch.value !== undefined
  return patchValueIsValid && trackedValue === undefined || areSame(trackedValue, patch.value)
}
export const matchesStoreValue = (state, patch, serverValue) => {
  assertIsValidPatch(patch)
  return areSame(patch.value, serverValue)
}

