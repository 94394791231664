import { POST_ASSET, PUT_ASSET_ATTRIBUTE, } from "@/store/graph/operations"

export default {
  [PUT_ASSET_ATTRIBUTE]: function (state, action) {
    const {typeName, attributeName, value} = action.payload
    state[typeName][attributeName] = value
  },
  [POST_ASSET]: function (state, action) {
    const patch = action.response
    Object.entries(patch).forEach(([typeName, assets]) => {
      assets.forEach(asset => {
        if (action.payload.requestId) asset.requestId = action.payload.requestId // allows components to find newly created assets (assetByRequestId getter)
        state[typeName][asset.id] = asset
      })
    })
  },

}
