import graphMutations from './graph/mutations'
import * as actions from './types'

const mutations = {
  ...graphMutations,
  [actions.FLASH_ERROR](state, value){
    state.error = value
  },
  [actions.FLASH_WARNING](state, message){
    state.warning = message
  },

}
export default mutations
